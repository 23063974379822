<template>
  <div>
    <portal to="header">
      <terminal-header
        :title="$t('Warehouses')"
        @barcode="handleBarcode"
      />
    </portal>

    <div>
      <q-table
        :style="`height: ${terminalContainerHeight}px`"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="$service.warehouse.isLoading"
        :filter="filter"
        :rows-per-page-options="[]"
        table-header-class="d-none"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
            @dblclick="onRowDblClick(props.row)"
          >
            <q-td
              key="details"
              :props="props"
            >
              <div class="row items-center q-pt-xs">
                <q-badge
                  :color="stateColors[props.row.state]"
                  :label="$t(props.row.state)"
                  class="q-py-xs"
                />

                <div class="col q-px-sm text-caption text-capitalize">
                  {{ props.row.type }}
                </div>

                <div v-if="props.row.created">
                  {{ $moment(props.row.created.date).format($appOptions.formats.date) }}
                </div>
              </div>

              <div class="text-caption">
                <small v-if="props.row._embedded.country">
                  {{ props.row._embedded.country.name }}
                </small>

                <small v-if="props.row._embedded.currency">
                  {{ props.row._embedded.currency.code }}
                </small>

                <div v-if="props.row._embedded.owner">
                  <small>{{ props.row._embedded.owner.name }}</small>
                </div>
              </div>

              <div class="text-caption">
                {{ props.row.comment }}
              </div>

              <div class="text-subtitle2 text-bold">
                {{ props.row.id }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'Warehouses',
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark'
      },
      items: []
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ])
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    loadDefaultItems () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ],
        filter: [
          { type: 'in', field: 'type', values: ['fulfillment', 'client'] }
        ]
      }

      return this.onRequest({ pagination })
    },
    handleBarcode (barcode) {
      const pagination = {
        page: 1,
        filter: [
          { type: 'eq', field: 'id', value: barcode.raw },
          { type: 'in', field: 'type', values: ['fulfillment', 'client'] }
        ]
      }

      this.onRequest({ pagination })
        .then(({ items }) => {
          if (items.length === 1) {
            this.onRowDblClick(items[0])
          }
        })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      return this.$service.warehouse.getAll(query)
        .then(({ page, totalItems, items, totalPages }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items

          return { page, totalItems, items, totalPages }
        })
    },
    onRowDblClick (row) {
      const link = this.$route.query.type === 'protocol'
        ? 'new-measure'
        : 'measure'

      this.$router.push(`/terminal/${link}/${row.id}`)
    }
  }
}
</script>
